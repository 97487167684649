/* container */
.qualification__container {
    max-width: 100%;  
}

/* button-qualification */
.qualification__tabs {
    display: flex;
    justify-content: center;        
    margin-bottom: var(--mb-3);         
}

.qualification__button {
    display: grid;
    text-align: center;
    font-size: var(--standard);
    font-weight: var(--semi-bold);
    color: var(--text-secondary);   
    margin: 0 var(--mb-2);
    cursor: pointer;
    transition: all 0.4s;    
}

/* qualification-icon */
.qualification__icon {
    font-size: 1rem;
    margin-right: var(--mb-0-25);
}

/* qualification-active */
.qualification__active {
    color: var(--title);
}

/* content */
.qualification__sections {
    display: grid;
    grid-template-columns: max-content;
    justify-content: center;
}

.qualification__content {
    display: none;
}

/* content-active */
.qualification__content-active {
    display: block;
}

/* data */
.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 2.5rem;
}

.qualification__data h3 {
    color: var(--text-secondary);
}

/* title */
.qualification__title {
    font-size: var(--standard);
    font-weight: var(--bold);
}

/* subtitle */
.qualification__subtitle {
    display: inline-block;
    font-size: var(--small);
    font-weight: var(--medium);
    color: var(--subtitle);
    margin-bottom: var(--mb-0-25);
}

/* calender */
.qualification__calender {
    font-size: var(--small);       
}

.qualification__calender i {
    margin-right: 0.3rem;
}

/* line */
.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-secondary);
    border-radius: 50%;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-secondary);
    transform: translate(6px, -7px);
}

/* =========== BREAKPOINTS =========== */
/* For medium devices */
@media screen and (max-width: 576px) {   
    /* data */
    .qualification__data {
        column-gap: 1rem;
    }
}

/* For medium devices */
@media screen and (max-width: 350px) {   
    /* data */
    .qualification__content {
        max-width: 300px;
    }
}
