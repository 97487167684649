/* contact-section */
#contact {
    width: 100%;
    height: 100%;
    background-color: var(--bg-three);    
}

/* container */
.contact__container {
    grid-template-columns: repeat(2,  max-content);
    justify-content: center;
    column-gap: 3rem;    
    margin-bottom: 3rem;    
}

/* contact-title */
.contact__title {
    text-align: center;
    font-size: var(--standard);
    font-weight: var(--bold);
    margin-bottom: var(--mb-3);
}

/* contact-card */
.contact__card {
    background-color: var(--bg-one);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border-radius: 0.75rem;
    text-align: center;
    align-items: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

/* contact-card-icon */
.contact__card-icon {
    font-size: 1.8rem;
    font-weight: var(--medium);
    color: var(--title);  
    margin-bottom: var(--mb-0-5);  
}

/* contact-card-title */
.contact__card-title {
    font-size: var(--standard);
    font-weight: var(--bold);
    margin-bottom: var(--mb-0-5);
}

/* contact-card-data */
.contact__card-data {
    display: block;
    font-size: var(--small);
    font-weight: var(--normal);    
    margin-bottom: var(--mb-0-5);
}

/* contact-button */
.contact__button {
    font-size: var(--small);
    font-weight: var(--medium);
    color: var(--subtitle);     
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;    
}

/* contact-button-icon */
.contact__button-icon {
    color: var(--text-secondary);
    margin-left: 0.5rem;
    transition: all 0.4s;  
}

.contact__button:hover .contact__button-icon {
    color: var(--title);
    transform: translateX(0.25rem);
}

/* contact-info */
.contact__info {
    display: grid;
    row-gap: 1.5rem;     
    grid-template-columns: 360px;
    justify-content: center;
}

/* contact-form */
.contact__form {
    display: grid;
    row-gap: 1.5rem;     
    grid-template-columns: 360px;
    justify-content: center;
}

/* contact-form-div */
.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

/* contact-form-input */
.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background: var(--bg-one);
    color: var(--subtitle);   
    font-size: var(--standard);
    font-weight: var(--medium); 
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

/* contact-form-tag */
.contact__form-tag {
    position: absolute;
    top: -0.65rem;
    left: 1.25rem;
    font-size: var(--smaller);
    font-weight: var(--semi-bold);
    color: var(--subtitle);    
    border-radius: 0.75rem;
    padding: 0.05rem 0.75rem;
    background-color: var(--bg-one);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--text-secondary);
    z-index: 10;
}

/* form-area */
.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}

/* button-send */
.button__send {    
    background-color: transparent;
    border: 2px solid var(--title);
    color: var(--title);
    padding: 0.8rem 1.25rem;
    border-radius: 6px;
    font-size: var(--standard);
    font-weight: var(--bold);
    align-items: center;
    text-align: center;
    transition: all 0.5s;
}

.button__send-icon {
    font-size: 1rem;
    margin-left: 0.3rem;
}

.button__send:hover {
    background-color: var(--title);
    color: var(--white);
}

/* contact-waves */
.contact__waves {
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;   
    margin-top: 1rem; 
}

/* =========== BREAKPOINTS =========== */
/* For medium devices */
@media screen and (max-width: 768px) {    
    /* container */
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }    
}

@media screen and (max-width: 576px) {   
    /* contact-info */
    .contact__info {
        grid-template-columns: 1fr;
    }

    /* contact-form */
    .contact__form {
        grid-template-columns: 1fr;
    }
}
