/* container */
.statistics__container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-bottom: var(--mb-1-5);
}

/* content */
.content {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: var(--mb-1-5);  
}

/* statistics-box */
.statistics__box {
    background-color: var(--bg-two);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border-radius: 1.5rem;
    text-align: center;
    align-items: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

/* statistics-icon */
.statistics__icon {
    font-size: 1.8rem;
    font-weight: var(--medium);
    color: var(--title); 
    margin-bottom: var(--mb-0-5);   
}

/* statistics-box */
.statistics__title {
    font-size: var(--standard);
    font-weight: var(--bold);
    margin-bottom: var(--mb-0-25);
}

/* statistics-subtitle */
.statistics__subtitle {
    font-size: var(--small);    
}

/* statistics-links */
.statistics__links {
    display: flex;
    column-gap: 1rem;   
    text-align: center;
    justify-content: center;   
    margin-top: var(--mb-0-75);    
}

/* statistics-link */
.statistics__link {
    font-size: var(--small);
    font-weight: var(--medium);
    color: var(--subtitle);     
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;     
}

/* statistics-link-icon */
.statistics__link-icon {
    color: var(--text-secondary);
    margin-left: 0.5rem;
    transition: all 0.4s;  
}

.statistics__link:hover .statistics__link-icon {
    color: var(--title);
    transform: translateX(0.25rem);
}

/* statistics-channel */
.statistics__channel {
    display: flex;
    column-gap: 1rem;   
    text-align: center;
    justify-content: center;   
    margin-top: var(--mb-0-75);    
}

/* statistics-button */
.statistics__button {
    background-color: transparent;
    border: 2px solid var(--title);
    color: var(--title);
    padding: 0.8rem 1.25rem;
    border-radius: 6px;
    font-size: var(--standard);
    font-weight: var(--bold);
    align-items: center;
    text-align: center;
    transition: all 0.5s;
}

.statistics__button-icon {
    font-size: 1rem;
    margin-left: 0.3rem;
}

.statistics__button:hover {
    background-color: var(--title);
    color: var(--white);
}

/* =========== BREAKPOINTS =========== */
/* For large devices */
@media screen and (max-width: 992px) {  
    /* container */
    .statistics__container {
        grid-template-columns: 1fr;
        justify-content: center;
        margin-bottom: var(--mb-1-5);
    }

    /* content */
    .content {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        margin-bottom: var(--mb-1-5);  
    }

    /* statistics-description */
    .statistics__description {
        text-align: center;
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {   
    /* content */
    .content {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin-bottom: var(--mb-1-5);  
    }
}
