/* header */
.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--black);
}

/* container */
.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

/* logotipo */
.nav__logo {
    width: 35px;
    height: auto;  
    margin-top: 5px;
    cursor: pointer;
}

/* menu-links */
.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--standard);
    font-weight: var(--semi-bold);
    color: var(--text-light);
    transition: all 0.2s;
}

/* display-none */
.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

/* active-link */
.active-link,
.nav__link:hover {
    color: var(--white);
}

/* change background header */
.scroll-header {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/* =========== BREAKPOINTS =========== */
/* For medium devices */
@media screen and (max-width: 768px) {    
    /* header */
    .header {
        top: initial;
    }

    /* container */
    .nav {
        height: calc(var(--header-height) + 1.5rem);
    }

    /* nav-menu */
    .nav__menu {
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        padding: 6rem 2rem 3rem;
        background-color: var(--black);
        box-shadow: 2px solid rgba(0, 0, 0, 0.15);
        border-radius: 0 0 1.5rem 1.5rem;
        transition: all 0.2s;
    }

    /* show-menu */
    .show-menu {
        top: 0;
    }

    /* menu-links */
    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;        
    }

    /* nav-icon */
    .nav__icon {
        font-size: 1.1rem;
        margin-bottom: 0.45rem;
    }

    /* nav-close */
    .nav__close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .nav__close:hover {
        color: var(--white);
    }

    /* nav-toggle */
    .nav__toggle {
        font-size: 1.5rem;
        cursor: pointer;
    }

    /* display-block */
    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}

/* For small devices */
@media screen and (max-width: 350px) { 
    /* nav-menu */
    .nav__menu {
        padding: 6rem 1rem 3rem;
    }

    /* menu-links */
    .nav__list {
        column-gap: 0;
    }
}
