/* portfolio-section */
#portfolio {
    width: 100%;
    height: 100%;
    background-color: var(--bg-three);
}

/* work-filters */
.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.work__item {
    color: var(--text-secondary);
    padding: 0.35rem 0.75rem;
    background-color: var(--bg-one);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    font-size: var(--small);
    font-weight: var(--semi-bold);
    border-radius: 1.5rem;
    text-transform: capitalize;
    cursor: pointer;    
}

.work__item:hover {
    background-color: var(--title);
    color: var(--white);
}

/* work-container */
.work__container {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    justify-content: center;    
}

/* work-card */
.work__card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    background-color: var(--bg-one);
    text-align: center;
    padding-bottom: 1.5rem;   
    transition: all 0.3s;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.work__card:hover {
    transform: scale(1.02);    
}

/* work-img */
.work__img {   
    margin-bottom: var(--mb-1);  
    border-radius: 1.5rem 1.5rem 0 0;  
}

/* work-title */
.work__title {
    font-size: var(--standard);
    font-weight: var(--bold);
    margin-bottom: var(--mb-0-25);
}

/* work-subtitle */
.work__subtitle {
    font-size: var(--small);
    font-weight: var(--medium);
    margin-bottom: var(--mb-0-25);
    color: var(--subtitle);
}

/* work-links */
.work__links {    
    display: flex;
    column-gap: 1rem;   
    text-align: center;    
    justify-content: center;
}

/* work-button */
.work__button {
    font-size: 1.5rem;
    font-weight: var(--semi-bold);
    color: var(--text-secondary);
    transition: all 0.5s;
}

.work__button:hover {
    color: var(--title);
}

/* active-work */
.active-work {
    background-color: var(--title);
    color: var(--white);
}

/* =========== BREAKPOINTS =========== */
/* For large devices */
@media screen and (max-width: 992px) {      
    /* work-container */
    .work__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {   
    /* work-container */
    .work__container {
        grid-template-columns: 1fr;
    }

    /* work-filters */
    .work__filters {
        column-gap: 0.25rem;
    }

    .work__item {
        font-size: var(--smaller);
        padding: 0.35rem 0.65rem;
    }
}
