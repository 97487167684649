/* about-section */
#about {
    width: 100%;
    max-height: max-content;
    background-color: var(--bg-one);    
}

/* container */
.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;    
    column-gap: 3rem;  
}

/* about-profile */
.about__profile {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    background-image: url('../../assets/profile.png');   
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;  
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

/* profile-img */
.profile__img {    
    border-radius: 0.75rem;
    margin-bottom: var(--mb-0-5);    
}

/* profile-info */
.profile__info {   
    margin-top: 235px;
    padding: 1rem;    
    background-color: rgba(0, 0, 0, 0.80);   
}

/* profile-info-icon */
.profile__info-icon {
    font-size: 1rem;  
    color: var(--text-secondary);
    display: grid;
    margin-bottom: 0.2rem;
    text-align: center;
}

.profile__info h1 {
    color: var(--title);
    margin-bottom: var(--mb-0-5);    
    text-align: center;
    font-size: var(--h3);
}

.profile__info p {    
    color: var(--white);
    font-size: var(--smaller);  
    font-weight: var(--medium);  
    margin-bottom: var(--mb-1);
    align-items: center;    
    text-align: center;
}

/* about-link */
.about__links { 
    display: flex;
    column-gap: 1rem;   
    text-align: center;
    justify-content: flex-end;   
}

.about__link {    
    color: var(--text-secondary);
    font-size: var(--smaller);
    font-weight: var(--semi-bold);  
    transition: all 0.4s;      
}

.about__link-icon {
    margin-left: 0.3rem;
}

.about__link:hover {
    color: var(--white);
}

/* about-data */
.about__data {
    width: 100%;
    height: 100%;
}

/* about-info */
.about__info {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: var(--mb-1-5);  
}

/* about-box */
.about__box {
    background-color: var(--bg-two);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 1.5rem;
    text-align: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

/* about-icon */
.about__icon {
    font-size: 1.8rem;
    font-weight: var(--medium);
    color: var(--title); 
    margin-bottom: var(--mb-0-5);   
}

/* about-title */
.about__title {
    font-size: var(--standard);
    font-weight: var(--bold);
    margin-bottom: var(--mb-0-5);
}

/* about-subtitle */
.about__subtitle {
    font-size: var(--small);    
}

/* about-description */
.about__description {    
    margin-bottom: var(--mb-1-5);
}

/* download-cv */
.download__cv {
    background-color: transparent;
    border: 2px solid var(--title);
    color: var(--title);
    padding: 0.8rem 1.25rem;
    border-radius: 6px;
    font-size: var(--standard);
    font-weight: var(--bold);
    align-items: center;
    text-align: center;
    transition: all 0.5s;
}

.download__cv-icon {
    margin-left: 0.3rem;
}

.download__cv:hover {
    background-color: var(--title);
    color: var(--white);
}

/* =========== BREAKPOINTS =========== */
/* For large devices */
@media screen and (max-width: 992px) {  
    /* container */
    .about__container {
        grid-template-columns: 1fr;
        text-align: center;
    }

    /* profile-info */
    .profile__info {
        margin-bottom: 25px;
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {   
    /* about-info */
    .about__info {
        grid-template-columns: 1fr;
    }
}
