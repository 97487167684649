/* container */
.services__container {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    column-gap: 1.5rem;
}

/* content */
.services__content {
    position: relative;
    background-color: var(--bg-two);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border-radius: 1.5rem;   
    text-align: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

/* services-icon */
.services__icon {
    display: block;
    font-size: 1.8rem;
    font-weight: var(--medium);
    color: var(--title);   
}

/* title-services */
.services__title {
    font-size: var(--standard);
    font-weight: var(--bold);
    margin-bottom: var(--mb-0-5);        
}

/* button-services */
.services__button {
    font-size: var(--small);
    font-weight: var(--medium);
    color: var(--subtitle);     
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
}

/* button-icon */
.services__button-icon {
    color: var(--text-secondary);
    margin-left: 0.5rem;
    transition: all 0.4s;   
}

.services__button:hover .services__button-icon {
    color: var(--title);
    transform: translateX(0.25rem);
}

/* services-modal */
.services__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
}

/* content-modal */
.services__modal-content {
    width: 500px;
    position: relative;
    background-color: var(--bg-two);
    padding: 4.5rem 3.5rem 3.5rem;
    border-radius: 1.5rem;    
}

/* button-close-modal */
.services__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--black);
    cursor: pointer;
}

/* title-modal */
.services__modal-title {
    font-size: var(--h2);  
    font-weight: var(--bold);
    margin-bottom: var(--mb-1);  
    text-align: center;
}

/* description-modal */
.services__modal-description {
    color: var(--subtitle);
    font-size: var(--small);
    font-weight: var(--semi-bold);
    margin-bottom: var(--mb-2);
    text-align: center;
}

/* list-modal */
.services__modal-services {
    row-gap: 0.75rem;
}

.services__modal-service {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;    
}

/* check-modal */
.services__modal-icon {
    color: var(--text-secondary);
    font-size: 1rem;
}

/* modal-info */
.services__modal-info {
    font-size: var(--smaller);
    font-weight: var(--medium);
}

/* active-modal */
.active-modal {
    opacity: 1;
    visibility: visible;
}

/* =========== BREAKPOINTS =========== */
/* For large devices */
@media screen and (max-width: 992px) {  
    /* container */
    .services__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {   
    /* container */
    .services__container {
        grid-template-columns: 1fr;
    }

    /* content-modal */
    .services__modal-content {
        padding: 4.5rem 1.5rem 1.5rem;
    }
}
