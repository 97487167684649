/* home-section */
#home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-image: url('../../assets/background.png');
    background-size: cover;
    background-position: center; 
}

/* home-title */
.home__title {
    font-size: var(--big);
    font-weight: var(--bold);
    margin-bottom: var(--mb-0-25);
    color: var(--white);
}

.home__title span {
    color: var(--title);
}

/* home-subtitle */
.home__subtitle {
    font-size: var(--h2);
    font-weight: var(--semi-bold);
    margin-bottom: var(--mb-2);
    color: var(--white);
}

.home__subtitle::before {
    content: "";
    width: 25px;
    height: 2px;
    display: inline-block;
    background: var(--text-secondary);
    margin: 6px 8px;
}

.home__subtitle::after {
    content: "";
    width: 25px;
    height: 2px;
    display: inline-block;
    background: var(--text-secondary);
    margin: 6px 8px;
}

/* home-description */
.home__description {
    width: 650px;   
    letter-spacing: 0.8px;
    font-size: var(--standard);
    font-weight: var(--medium);
    margin-bottom: var(--mb-1);
    color: var(--white);
}

/* social-media */
.home__social {
    display: flex;
    column-gap: 1rem;     
    justify-content: center;
    font-size: 1.3rem;
    font-weight: var(--semi-bold);
}

.home__social-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid var(--text-main);
    color: var(--text-main);
    text-align: center;
    align-items: center;
    transition: all 0.5s;
}

.home__social-icon:hover {
    border: 2px solid var(--white);
    color: var(--white);
}

/* scroll-down */
.home__scroll {
    position: absolute;
    right: 0;
    bottom: 2rem;
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;      
    padding: 1.5rem;
    align-items: center;
    justify-content: center;
}

/* scroll-name */
.home__scroll-name {
    color: var(--white);
    font-size: var(--standard);
    font-weight: var(--medium);
    writing-mode: vertical-lr;   
    display: flex;     
    margin-bottom: 1rem;
    margin-left: 4px;
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}

/* home-waves */
.home__waves {
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;  
    bottom: 0;    
}

.wave1 use {    
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
}

.wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
}

.wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
}

@keyframes move-forever1 {
    0% {
    transform: translate(85px, 0%);
    }

    100% {
    transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

/* =========== BREAKPOINTS =========== */
/* For medium devices */
@media screen and (max-width: 768px) {    
    /* home-description */
    .home__description {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {   
    /* scroll-down */
    .home__scroll {
        display: none;
    }
}

/* For small devices */
@media screen and (max-width: 350px) { 
    /* social-media */
    .home__social {
        column-gap: 0.7rem;
    }
}
